import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  @Input() audioBase64: string;

  @ViewChild('audioElement', { static: true }) audioElement!: ElementRef<HTMLAudioElement>;

  audio = new Audio();
  isPlaying = false;
  currentTime = 0;
  duration = 0;
  progress = 0;

  ngOnInit() {
    this.audio.src = `data:audio/ogg; codecs=opus;base64,${this.audioBase64}`;
    this.audio.addEventListener('loadedmetadata', () => {
      this.duration = this.audio.duration * 1000; // in milliseconds
    });

    this.audio.addEventListener('timeupdate', () => {
      this.currentTime = this.audio.currentTime * 1000; // in milliseconds
      this.progress = (this.audio.currentTime / this.audio.duration) * 100;
    });

    this.audio.addEventListener('ended', () => {
      this.isPlaying = false;
    });
  }

  togglePlayPause() {
    if (this.isPlaying) {
      this.audio.pause();
    } else {
      this.audio.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  seek(event: MouseEvent) {
    const container = event.target as HTMLElement;
    const offsetX = event.offsetX;
    const containerWidth = container.offsetWidth;
    const seekTime = (offsetX / containerWidth) * this.audio.duration;
    this.audio.currentTime = seekTime;
  }

  dragStart(event: DragEvent) {
    event.preventDefault();
  }

  drag(event: DragEvent) {
    if (!event.clientX) return;
    const container = event.target as HTMLElement;
    const rect = container.parentElement!.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const containerWidth = rect.width;
    const dragProgress = (offsetX / containerWidth) * 100;
    this.progress = Math.max(0, Math.min(dragProgress, 100));
  }

  dragEnd(event: DragEvent) {
    if (!event.clientX) return;
    const container = event.target as HTMLElement;
    const rect = container.parentElement!.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const containerWidth = rect.width;
    const seekTime = (offsetX / containerWidth) * this.audio.duration;
    this.audio.currentTime = seekTime;
  }
}
