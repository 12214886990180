
<div class="popup-body">
  <!-- Priorität ändern -->
  <div id="close_icon" *ngIf="showWindow === 'changePriority'" (click)="selectionCloseEMitter.emit()">X</div>
  <div class="popup-body" *ngIf="showWindow === 'changePriority'">
    <div class="popup-container">
      <div class="popup-header">
          Priorität ändern
      </div>
      <ul class="popup-priorities">
        <li *ngFor="let type of selectPriorities">
          <button
            type="button"
            [ngClass]="{
              'hoch': selectedPriority === 0 && type.value == selectedPriority,
              'mittel': selectedPriority === 1 && type.value == selectedPriority,
              'niedrig': selectedPriority === 2 && type.value == selectedPriority
            }"
            (click)="onSelectPriority(type.value)">
            <span class="name">{{ type.label }}</span>
          </button>
        </li>
      </ul>
        <button class="popup-footer" (click)="changePriority()">Speichern</button>
    </div>
  </div>

  <!-- Anruf verschieben -->
  <div id="close_icon_date" *ngIf="showWindow === 'changeDate'" (click)="selectionCloseEMitter.emit()">X</div>
  <div class="popup-body" *ngIf="showWindow === 'changeDate'">
    <div class="popup-container">
      <div *ngIf="!changeDateIndividually">
      <div class="popup-header">
          Anruf verschieben
      </div>
        <!-- Auf morgen verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateByOneDay = !changeDateByOneDay">
              <div class="slider_still" [ngClass]="changeDateByOneDay ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateByOneDay  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateByOneDay ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Auf morgen verschieben</div>
          </div>
        </div>
        <!-- Tage verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateByDays = !changeDateByDays">
              <div class="slider_still" [ngClass]="changeDateByDays ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateByDays  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateByDays ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Tage verschieben</div>
          </div>
          <input class="input_field" type="text" [(ngModel)]="daysToShift">
        </div>
        <!-- Wochen verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateByWeek = !changeDateByWeek">
              <div class="slider_still" [ngClass]="changeDateByWeek ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateByWeek  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateByWeek ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Wochen verschieben</div>
          </div>
          <input class="input_field" type="text" [(ngModel)]="weeksToShift">
        </div>
        <!-- Individuell verschieben -->
        <div class="button_with_input">
          <div class="flex_slider">
            <div class="slider_container" (click)="changeDateIndividually = !changeDateIndividually">
              <div class="slider_still" [ngClass]="changeDateIndividually ? '' : 'slider_still_on'"></div>
              <div class="slider_move" [ngClass]="changeDateIndividually  ? 'slider_on' : 'slider_off'"></div>
            </div>
            <div [ngClass]="changeDateIndividually ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Individuell verschieben</div>
          </div>
        </div>
      </div>

      <!-- Individuell verschieben -->
      <div class="popup-header-2"  *ngIf="changeDateIndividually">
        <button class="button_icon_lbl" (click)="changeDateIndividually = !changeDateIndividually">
          <img src="../../../../../assets/navarrow.svg" style="rotate: 90deg;">
          Zurück
        </button>
        Individuell verschieben
      </div>
      <div class="individual_date" *ngIf="changeDateIndividually">
        <form [formGroup]="form">
          <!-- Datum wählen -->
          <div class="input_wrapper_planning">
            <label class="lbl_regular_12">Datum wählen</label>
            <input #inputDate1
              type="date"
              id="input-due-date"
              [min]="minDate"
              formControlName="due_date"
              [class.date-input--has-value]="inputDate1.value != null">
          </div>
          <!-- Uhrzeit wählen -->
          <div class="input_wrapper_planning">
            <label class="lbl_regular_12">Uhrzeit wählen (optional)</label>
            <div class="input_wrapper">
              <input #inputDate2 type="time" placeholder="---" formControlName="time">
            </div>
          </div>
        </form>
      </div>
      <button class="popup-footer" (click)="changeDate()">Verschieben</button>
    </div>
  </div>
</div>
