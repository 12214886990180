<div class="audio-player">
  <button id="audio-player-button"
    (click)="togglePlayPause()">
    <img [src]="isPlaying ? './assets/philpause.svg' : './assets/philplay.svg'">
  </button>
  <div class="progress-container" (click)="seek($event)">
    <div class="progress-bar" [style.width.%]="progress"></div>
    <!-- <div
      class="progress-thumb"
      [style.left.%]="progress"
      draggable="true"
      (dragstart)="dragStart($event)"
      (drag)="drag($event)"
      (dragend)="dragEnd($event)"
    ></div> -->
  </div>
  <span>{{ currentTime | date:'mm:ss' }} / {{ duration | date:'mm:ss' }}</span>
</div>
