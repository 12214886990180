import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Call, TIMETYPE } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { ENUMSTATUS } from 'src/app/helper/Enums';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { DateHelper } from 'src/app/utils/DateHelper';

@Component({
  selector: 'app-phonelist-popup',
  templateUrl: './phonelist-popup.component.html',
  styleUrls: ['./phonelist-popup.component.scss']
})
export class PhonelistPopupComponent implements OnInit {

  @Input() selectedCall: Call;
  @Input() showWindow: string;

  @Output() selectionSaveEmitter = new EventEmitter<any>();
  @Output() selectionCloseEMitter = new EventEmitter<any>();

  public form: FormGroup;
  public selectPriorities = [
    { label: 'Hoch', value: 0 },
    { label: 'Mittel', value: 1 },
    { label: 'Niedrig', value: 2 }
  ];
  public selectedPriority: number;
  public changeDateByOneDay: boolean = false;
  public changeDateByDays: boolean = false;
  public changeDateByWeek: boolean = false;
  public changeDateIndividually: boolean = false;
  public daysToShift: number = 1;
  public weeksToShift: number = 1;
  public minDate;

  constructor(
    private formbuilder: FormBuilder,
    private phoneCallService: PhoneCallService
  ) { }

  ngOnInit(): void {
    this.form = CallForm.getForm(this.formbuilder, this.selectedCall);
    this.selectedPriority = this.selectPriorities[this.selectedCall.priority].value;
    this.minDate = formatDate(new Date(),'yyyy-MM-dd','de');
    this.form.get('time')?.valueChanges.subscribe(value => {
      if(value) {
        this.selectedCall.time_type = TIMETYPE.EXACT;
      }
    });
  }

  onSelectPriority(value: number): void {
    this.selectedPriority = value;
    this.selectedCall.priority = value;
  }

  changePriority()
  {
   this.updatePhoneCall();
   this.selectionSaveEmitter.emit(this.selectedCall)
  }

  changeToTomorrow() {
      const oneDay = 24 * 60 * 60 * 1000;
      this.selectedCall.due_date += oneDay;
  }


  changeByDays(){
    const oneDay = 24 * 60 * 60 * 1000;
    this.selectedCall.due_date += this.daysToShift * oneDay;
    this.daysToShift = 0;
  }

  changeByWeeks(){
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    this.selectedCall.due_date += this.weeksToShift * oneWeek;
    this.weeksToShift = 0;
  }

  changeDate(){

    // change to tomorrow
    if(this.changeDateByOneDay && !this.changeDateByDays && !this.changeDateByWeek)
    {
      this.changeToTomorrow();
    }
    // change by eneterd days
    if(this.changeDateByDays && !this.changeDateByOneDay)
    {
      this.changeByDays();
    }
    // change by entered weeks
    if(this.changeDateByWeek && !this.changeDateByOneDay)
    {
      this.changeByWeeks();
    }

    if(this.changeDateIndividually)
    {
      this.selectedCall.due_date = DateHelper.getTimestampFromInputDate(this.form.get('due_date').value);
      const time = this.form.get('time').value;
      const hour = time.split(':')[0];
      const min = time.split(':')[1];
      let date = new Date(this.selectedCall.due_date);
      date.setHours(hour);
      date.setMinutes(min);
      this.selectedCall.due_date = date.getTime();
    }
    this.updatePhoneCall();
    this.selectionSaveEmitter.emit(this.selectedCall)
  }


  async updatePhoneCall(){
    await lastValueFrom(this.phoneCallService.updatePhoneCall(this.selectedCall));
  }
}
