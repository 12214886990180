import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-phonelist-action-menu',
  templateUrl: './phonelist-action-menu.component.html',
  styleUrls: ['./phonelist-action-menu.component.scss']
})
export class PhonelistActionMenuComponent implements OnInit {

  @Output() editEmitter = new EventEmitter<undefined>();
  @Output() deleteEmitter = new EventEmitter<undefined>();
  @Output() openPopupEmitter = new EventEmitter<string>();
  @Output() moveEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
}
