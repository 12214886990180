import { Call } from "../Call";


export class CallFactory{

    static jsonFactory(rawBody: any): Call[] {
        const calls: Call[] = [];
        for(let call of rawBody){
            calls.push(this.jsonFactoryOne(call));
        }
        return calls;
    }

    static jsonFactoryOne(rawCall: any): Call {
        const object = new Call(
            rawCall.call_id,
            rawCall.user_id,
            rawCall.contact_first_name,
            rawCall.contact_last_name,
            rawCall.company_name,
            rawCall.phone,
            rawCall.category,
            rawCall.created,
            rawCall.note,
            rawCall.driver_note,
            rawCall.driver_mp3,
            rawCall.due_date,
            rawCall.priority,
            rawCall.status,
            rawCall.sort_value,
            rawCall.customer_id,
            rawCall.contact_person_id,
            rawCall.time_type,
        )
        return object;
    }
}
