<div id="phonecall-container" *ngIf="selectedCall">
  <div class="phonecall-info" [class.active]="infoActive">
    <div id="user-details-header">
      <!-- <div id="user-details-header-images">
        <img class="user_img_container"
            [ngClass]="selectedCall?.user?.picture ? 'user-img-creator' : 'user_img_placeholder'"
            [src]="selectedCall?.user?.getAvatar()">
      </div> -->
      <div class="img-user-big-circle">
        <img [src]="selectedCall?.user?.getAvatar()"/>
      </div>
      <div class="user-details-header-txt-wrapper">
        <div>
          <label class="user-title-bold">#{{selectedCall.call_id}}</label>
          <label class="user-title" [innerHTML]="selectedCall.note != null &&  selectedCall.note.length > 0 ? (' ' + selectedCall.note | sanitizeHtml) : '' "></label>
        </div>
        <div id="user-details-header-row-1">
          <label class="user-label-info">Erstellt von {{selectedCall.user?.getFullName()}}, {{selectedCall.created | date: 'dd.MM.yyyy'}}</label>
        </div>
      </div>
    </div>
    <div id="user-details-body-inner">
      <div id="user-details-body-list">
        <div class="user-details-body-msg">
          <div class="user-details-body-msg-line">
            <!-- <div class="msg-vertical-line"></div> -->
            <div class="msg-vertical-line-img">
              <img src="./assets/memo.svg">
            </div>
            <div class="msg-horizontal-line"></div>
          </div>
          <div class="img-user-big-small-placeholder">
            <img [src]="'./assets/userimageplaceholder.svg'"/>
          </div>
          <div class="user-details-body-msg-content">
            <div class="body-details-player-row">
              <app-player
              [audioBase64]="selectedCall.driver_mp3"
              ></app-player>
            </div>
            <label class="label-bold">
              Inhalt der Nachricht:
              <span>{{selectedCall.driver_note}}</span>
            </label>
            <label class="label-light">
              von {{selectedCall.user.firstName + ' ' + selectedCall.user.lastName}}
            </label>
          </div>
        </div>
      </div>
    </div>
      <!-- TICKET ERSTELLEN-->
    <app-phonelist-tickets
      #appPhonelistTicket
      [users]="users"
      [customers]="customers"
      [call]="selectedCall"
      (ticketCreatedEmitter)="removeCallFromList(selectedCall)"
    ></app-phonelist-tickets>
  </div>

<!-- ANRUFS-INFORMATION-->
  <div class="phonecall-details-info" [class.active]="infoActive">
    <div id="phonecall-details-info-button" (click)="infoActive = !infoActive">
      <img [class.phonecall-details-info-button-icon-active]="infoActive" src="./assets/arrowdropdown.svg">
      <label>Anrufs-Informationen</label>
    </div>
    <div *ngIf="infoActive">
      <div class="phonecall-details-info-section">
        <label class="user-label-info">Anruf</label>
        <label> {{selectedCall.created | date: ' dd.MM.yyyy, HH:mm'}}</label>
      </div>
      <div class="phonecall-details-info-section">
        <label class="user-label-info">Planung und Priorisierung</label>
        <label>{{selectedCall.due_date | date: 'dd.MM.yyyy'}}, {{timeTyp}}</label>
        <label class="lbl-tag"
        [ngClass]="{
          'hoch': selectedCall.priority == 0,
          'mittel': selectedCall.priority == 1,
          'niedrig': selectedCall.priority == 2
        }">{{selectPriorities[selectedCall.priority].label}}</label>
      </div>
      <div class="phonecall-details-info-section">
        <label class="user-label-info">Kontakt</label>
        <label class="lbl-tag"
        [ngClass]="{
          'kunde': selectedCall.category == 'Kunde',
          'interessent': selectedCall.category == 'Interessent',
          'partner': selectedCall.category == 'Partner',
          'lieferant': selectedCall.category == 'Lieferant',
          'privat': selectedCall.category == 'Privat',
          'sonstige': selectedCall.category == 'Sonstige'
        }">{{selectedCall.category}}</label>
        <div class="label-container">
          <label class="user-label-info">Firma</label>
          <label class="user-info">{{selectedCall.company_name}}</label>
        </div>
        <div class="label-container">
          <label class="user-label-info">Vorname</label>
          <label class="user-info">{{selectedCall.contact_first_name ? selectedCall.contact_first_name : ' ---'}}</label>
        </div>
        <div class="label-container">
          <label class="user-label-info">Nachname</label>
          <label class="user-info">{{selectedCall.contact_last_name ? selectedCall.contact_last_name : ' ---'}}</label>
        </div>
        <div class="label-container">
          <label class="user-label-info">Tel.:</label>
          <label class="user-info">{{selectedCall.phone}}</label>
        </div>
      </div>
      <div class="phonecall-details-info-section-no-border">
        <label class="user-label-info">Anruf für</label>
        <div class="label-container">
          <img class="user_img_container"
          [ngClass]="selectedCall?.user?.picture ? 'user_img' : 'user_img_placeholder'"
          [src]="selectedCall.user?.getAvatar()">
          <label>{{selectedCall.user?.getFullName()}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="empty-list" *ngIf="!selectedCall">
  <label>Keine offene Bearbeitungen</label>
</div>
