import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThreadService } from 'src/app/service/thread/thread.service';

@Component({
  selector: 'app-dialog-image-open',
  templateUrl: './dialog-image-open.component.html',
  styleUrls: ['./dialog-image-open.component.scss']
})
export class DialogImageOpenComponent implements OnInit {
  @Output() closeImageOpenEmitter = new EventEmitter<any>();
  @Input() imageUrl: string = '';

  constructor() {}

  ngOnInit(): void {
    const openImage = document.getElementById("open-image") as HTMLImageElement;

    if (openImage) {
      openImage.onload = () => {
        const width = openImage.offsetWidth;
        const height = openImage.offsetHeight;

      if (height > width) {
        const dialogWindow = openImage.closest('.dialog_window');
        if (dialogWindow) {
          dialogWindow.classList.add('portrait');
        }
      } else {
        const dialogWindow = openImage.closest('.dialog_window');
        if (dialogWindow) {
          dialogWindow.classList.remove('portrait');
        }
      }
    }
  }
}

  close() {
    this.closeImageOpenEmitter.emit();
  }
}
