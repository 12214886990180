import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Call } from 'src/app/buisness-object/call/Call';


@Component({
  selector: 'app-phonelist-sidebar-item',
  templateUrl: './phonelist-sidebar-item.component.html',
  styleUrls: ['./phonelist-sidebar-item.component.scss']
})

export class PhonelistSidebarItemComponent implements OnInit {

  @Input() active: boolean = true;
  @Input() call: Call;
  
  public slectedCategories = [
    { label: 'Kunde', value: 1 },
    { label: 'Interessent', value: 2 },
    { label: 'Partner', value: 3 },
    { label: 'Lieferant', value: 4 },
    { label: 'Privat', value: 5 },
    { label: 'Sonstige', value: 6 },
  ];
  public selectedCategory: number;;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {}

  onCallSelected() {
    this.router.navigate(['phone'], { queryParams: this.getTicketRouteQueryParams(this.call) });
  }

  getTicketRouteQueryParams(call: Call): any {
    return {
      call_id: this.call.call_id
    }
  }

}
