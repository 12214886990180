<div id="sidebar-list">
  <div id="open_pins_list_container">
    <div id="elements_container">
      <div *ngFor="let calls of groupedCallsByCreated; let i = index;">
        <div class="list_title_inner" (click)="showListAction(i)" >{{calls[0].created | date: 'EEEE, dd.MMM.YYYY'}}
          <img class="list_title_inner_icon" [ngStyle]="{'transform': hideList(i) ? 'rotate(180deg)' : ''}" src="./assets/arrowdropdown.svg">
        </div>
        <div *ngFor="let call of calls; let i = index">
          <div class="list_container" *ngIf="!hideList(i)">
            <app-phonelist-sidebar-item
            [active]="paramID == call.call_id"
            [call]="call"
            ></app-phonelist-sidebar-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
