import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { UserService } from 'src/app/service/user/user.service';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/service/login/login.service';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { GlobalVariables } from 'src/app/helper/GlobalVariables';
import { BaseComponent } from '../BaseComponent';
import { environment } from 'src/environments/environment';
import { MobileService } from 'src/app/service/mobile/mobile.service';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public registeredUser!: User;
  public users: User[] =[];
  public showDropdown = false;
  @ViewChild('menu_dropdown') menu_dropdown: ElementRef;
  public listener!: () => void;
  public selectedMenu = 1;
  public dialogQuerySubsription!: Subscription;
  public dialogNofitySubscription!: Subscription;
  public routeSubscription!: Subscription;
  public closeMobileMenuSubscription!: Subscription;
  public notifyThreads: Thread[] = [];
  public customers: Customer[] = [];
  public reloadInterval: any;
  public showTicketBox = false;
  public showSearchDialog = false;
  public version = environment.VERSION;
  public isMobileMenuOpen = false;
  
  constructor(
    private renderer: Renderer2,
    private uService: UserService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private dService: DialogService,
    private lService: LoginService,
    private tService: ThreadService,
    private cService: CustomerService,
    private activateRoute: ActivatedRoute,
    private mService: MobileService
  ) {
    super();
    this.dialogNofitySubscription = this.tService.notificationTicketChange$.subscribe((data: any) => {
      for(let i = 0; i < this.notifyThreads.length; i++){
        if(this.notifyThreads[i].threadId == data.threadId){
          this.notifyThreads.splice(i,1);
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getDataServerside();
    }, 1000);
    this.setSubscriptions();
    /*
    this.reloadInterval = setInterval(() => {
      this.getNotifications();
    }, 60000);
    */
    this.setKeyListeners();
  }

  ngAfterViewInit(): void {
    this.setListeners();
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe;
    if(this.closeMobileMenuSubscription) this.closeMobileMenuSubscription.unsubscribe;
    if(this.dialogNofitySubscription) this.dialogNofitySubscription.unsubscribe;
    if(this.routeSubscription) this.routeSubscription.unsubscribe;
    if(this.reloadInterval) clearInterval(this.reloadInterval);
    document.removeEventListener('keydown', event => {});
  }

  setKeyListeners() {
    document.addEventListener('keydown', event => {
      if(event.ctrlKey && event.shiftKey && event.key == 'f'){
        event.preventDefault();
        this.selectionSearch();
      }
    });
  }

  getNotifications() {
    if(localStorage.getItem('token')){
      this.tService.getNotifications().subscribe((result) => {
        if(result){
          this.notifyThreads = result;
          this.mergeData();
        }
      })
    }
  }

  getDataServerside() {
    if(localStorage.getItem('token')){
      forkJoin({
        one: this.uService.getUsers(),
        two: this.tService.getNotifications(),
        three: this.cService.getCustomers(false)
      }).subscribe((result) => {
        if(result){
          this.users = result.one;
          this.notifyThreads = result.two;
          this.customers = result.three;
          let currentUserId = Number(localStorage.getItem('user_id'));
          for(let user of this.users){
            if(user.userId == currentUserId){
              this.registeredUser = user;
              break;
            }
          }
          this.mergeData();
        }
      })
    }
  }

  mergeData() {
    for(let thread of this.notifyThreads){
      for(let customer of this.customers){
        if(thread.customerId == customer.customer_id){
          thread.customer = customer;
        }
      }
      for(let user of this.users){
        if(thread.userId == user.userId){
          thread.user = user;
        }
      }
    }
  }

  setSubscriptions() {
    this.routeSubscription = this.router.events.subscribe((value) => {
      if(value instanceof NavigationStart){
        this.setMenu(value.url);
      }
    });
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_customer_create'){
          GlobalVariables.is_create_process = false;
          this.loadPage(value.submit_value);
        } else if(value.typ == 'logout_action') {
          this.lService.logout();
        }
      }
    });
    this.closeMobileMenuSubscription = this.mService.closeMobileMenuQuery$.subscribe(() => {
      this.isMobileMenuOpen = false;
    });
  }

  setListeners() {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdown && !this.menu_dropdown.nativeElement.contains(e.target)) this.showDropdown = false;
    })
  }

  toggleMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  handleTicketBox(set: boolean) {
    let el = document.getElementById("btn_open_notify");
    this.showTicketBox = set;
    if(el){
      if(this.showTicketBox) el.style.zIndex = "10000";
      else el.style.zIndex = "unset";
    }
  }

  openTicket(thread: Thread) {
    thread.notifyOpen = false;

    if(thread.project_id) {
      if(this.router.url.includes('/customers')){
        var url = this.router.createUrlTree([], { relativeTo: this.activateRoute, queryParams: { customerId: thread.customerId, projectId: thread.project_id, ticketId: thread.threadId } }).toString();
        this.router.navigateByUrl(url);
      } else {
        this.router.navigate(['customers'], { queryParams: { customerId: thread.customerId, projectId: thread.project_id, ticketId: thread.threadId } });
      }
    } else {
      if(this.router.url.includes('/customers')){
        var url = this.router.createUrlTree([], { relativeTo: this.activateRoute, queryParams: { customerId: thread.customerId, ticketId: thread.threadId } }).toString();
        this.router.navigateByUrl(url);
      } else {
        this.router.navigate(['customers'], { queryParams: { customerId: thread.customerId, ticketId: thread.threadId } });
      }
    }
    this.showTicketBox = false;
  }

  sanitize(data: any): SafeHtml{
    return this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + data);
  }

  getTitle(): string {
    switch (this.selectedMenu) {
      case 1: return 'Kunden'; break;
      case 2: return 'Interne Dokumentation'; break;
      case 3: return 'Tickets'; break;
      case 4: return 'Telefonliste'; break;
      default: return ''; break;
    }
  }

  reload(page: string) {
    if(this.router.url == '/customers/create' && GlobalVariables.is_create_process){
      this.dService.openQuery(
        {
          title: 'Verwerfen',
          message: 'Wenn Sie fortsetzen gehen alle Änderungen verloren.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verwerfen',
          typ: 'cancel_customer_create',
          showClose: true,
        }
      );
      this.loadPage(page);
    } else {
      this.loadPage(page);
    }
  }

  loadPage(page: string) {
    this.showDropdown = false;
    if(this.router.url == page){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([page])
      });
    } else {
      this.router.navigate([page]);
    }
  }

  setMenu(url: string) {
    if(url.includes('/customers')){
      this.selectedMenu = 1;
    }
    if(url.includes('/documentation')){
      this.selectedMenu = 2;
    }
    if(url.includes('/tickets')){
      this.selectedMenu = 3;
    }
    if(url.includes('/phone')){
      this.selectedMenu = 4;
    }
  }

  openWebsite() {
    window.open('https://software-entwicklung-graz.at/', '_blank')?.focus();
  }

  logoutAction() {
    this.dService.openQuery(
      {
        title: 'Abmelden',
        message: 'Sind Sie sicher das Sie sich abmelden möchten?',
        btn_submit_txt: 'Abmelden',
        typ: 'logout_action',
        showClose: true,
      }
    );
  }

  openNotifyDialog() {
    this.showTicketBox = true;
  }

  selectionSearch() {
    document.removeEventListener('keydown', event => {});
    this.showSearchDialog = true;
  }

  closeSearchDialog() {
    this.showSearchDialog = false;
    this.setKeyListeners();
  }
}
