import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Call, TIMETYPE } from "../Call";
import { CustomerForm } from "../../customer/form/CustomerForm";
import { DateHelper } from "src/app/utils/DateHelper";

export class CallForm {

  public static getForm(formBuilder: FormBuilder, obj?: Call): FormGroup {
    let defaultDate = new Date();
    let defaultTimeTyp;
    if(defaultDate.getHours() < 12){
      defaultTimeTyp = TIMETYPE.MORNING;
    } else {
      defaultTimeTyp = TIMETYPE.AFTERNOON;
    }
    let form = formBuilder.group({
      call_id: [obj? obj.call_id : null],
      user_id: [obj? obj.user_id : null],
      category: [obj ? obj.category : "Interessent"],
      created: [obj ? obj.created : null],
      note: [obj ? obj.note : null],
      driver_note: [obj ? obj.driver_note : null],
      driver_mp3: [obj ? obj.driver_mp3 : null],
      due_date: [obj ?  DateHelper.getFormatDateFromTimestamp(obj.due_date) : DateHelper.getFormatDateFromTimestamp(defaultDate.getTime()), Validators.required],
      priority: [obj ? obj.priority : 1],
      status: [obj ? obj.status : null],
      customer_id: [obj ? obj.customer_id : null],
      contact_person_id: [obj ? obj.contact_person_id : null],

      customer: [obj && obj.customer ? obj.customer : null],
      contact_form: [CustomerForm.addContactForm(formBuilder)],
      is_contact_to_create: [false],
      time: [obj && obj.due_date ? DateHelper.getFormatTimeFromTimestamp(obj.due_date) : DateHelper.getFormatTimeFromTimestamp(defaultDate.getTime())],
      time_type: [obj ? obj.time_type : defaultTimeTyp],
      company_name: [obj && obj.company_name ? obj.company_name : null],
    })

    return form;
  }

  // public static getForm(formBuilder: FormBuilder, obj?: Call): FormGroup {
  //   let fuck = CustomerForm.addContactForm(formBuilder, obj?.customer?.contact_persons[obj.contact_person_id]);
  //   if(obj && !obj.isExistingContact()){
  //     fuck.get('contact_first_name').setValue(obj.contact_first_name);
  //     fuck.get('contact_last_name').setValue(obj.contact_last_name);
  //     fuck.get('position').setValue(obj.contact_position);
  //   }
  //   let form = formBuilder.group({
  //     call_id: [obj? obj.call_id : null],
  //     user_id: [obj? obj.user_id : null],
  //     contact_first_name: [obj ? obj.contact_first_name : null],
  //     contact_last_name: [obj ? obj.contact_last_name : null],
  //     company_name: [obj ? obj.company_name : null],
  //     phone: [obj ? obj.phone : null, [Validators.required, phoneNumberValidator]],
  //     category: [obj ? obj.category : "Interessent"],
  //     created: [obj ? obj.created : null],
  //     note: [obj ? obj.note : null],
  //     driver_note: [obj ? obj.driver_note : null],
  //     driver_mp3: [obj ? obj.driver_mp3 : null],
  //     due_date: [obj ?  DateHelper.getFormatDateFromTimestamp(obj.due_date) : DateHelper.getFormatDateFromTimestamp(new Date().getTime()), Validators.required],
  //     priority: [obj ? obj.priority : 1],
  //     status: [obj ? obj.status : null],
  //     sort_value: [obj ? obj.sort_value : null],
  //     customer_id: [obj ? obj.customer_id : null],
  //     contact_person_id: [obj ? obj.contact_person_id : null],

  //     customer: [obj && obj.customer ? obj.customer : null],
  //     customer_form: [CustomerForm.createCustomerForm(formBuilder, obj?.customer)],
  //     contact_form: [fuck],
  //     is_contact_to_create: [false],
  //     user: [obj && obj.user ? obj.user : null],
  //     contact_full_name: [obj ? obj.getFirstAndLastNameCustomerContact() : null],
  //     contact_email: [obj && obj.contact_email ? obj.contact_email : null],
  //     time: [obj && obj.due_date ? DateHelper.getFormatTimeFromTimestamp(obj.due_date) : null],
  //     timeAsString: [obj ? null : 'Vormittag'],
  //     day_time: [null],
  //     gender: [null],
  //     time_type: [obj ? obj.time_type : TIMETYPE.MORNING],
  //     contact_position: [null]
  //   })

  //   return form;
  // }


public static getObject(form: FormGroup): Call {
  const contact = CustomerForm.createContact(form.get('contact_form').value);
  let obj = new Call(
    form.get('call_id').value,
    form.get('user_id').value,
    contact.firstName,
    contact.lastName,
    contact.company_name,
    contact.phone,
    form.get('category').value,
    form.get('created').value,
    form.get('note').value,
    form.get('driver_note').value,
    form.get('driver_mp3').value,
    DateHelper.getTimestampFromInputDate(form.get('due_date').value),
    form.get('priority').value,
    form.get('status').value,
    null,
    form.get('customer_id').value,
    contact.contact_id,
    form.get('time_type').value,
  );
  obj.customer = form.get('customer').value;
  // if(!form.get('is_contact_to_create').value){
  //   obj.contact_last_name = form.get('contact_form').value.get('contact_last_name').value;
  //   obj.contact_first_name = form.get('contact_form').value.get('contact_first_name').value;
  //   obj.contact_full_name = `${obj.contact_first_name} ${obj.contact_last_name}`;
  //   obj.phone = form.get('contact_form').value.get('contact_phone').value;
  //   obj.contact_email = form.get('contact_form').value.get('contact_email').value;
  //   obj.contact_position = form.get('contact_form').value.get('position').value;
  // }
  return obj;
}

}

export function phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  const phoneNumberPattern = /^[+]?[0-9]{8,17}$/; ///^(\+43|0043|0)\d{4,13}$/; ///^[+]?[0-9]{10,15}$/;
  const isValid = phoneNumberPattern.test(control.value);
  return isValid ? null : { invalid: true };
}
