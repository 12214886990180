import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Call } from 'src/app/buisness-object/call/Call';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';

@Component({
  selector: 'app-phonelist-sidebar',
  templateUrl: './phonelist-sidebar.component.html',
  styleUrls: ['./phonelist-sidebar.component.scss']
})
export class PhonelistSidebarComponent {

  @Input() calls: Call[];
  public groupedCallsByCreated: Call[][] = [];
  public showListItems: boolean[] = [];
  public selected = false;
  public paramID;
  public routeSubscription: Subscription;

  constructor(
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setRouteSubscriptions();
    this.groupedCallsByCreated = this.groupCallsByCreated();
  }

  intiView(calls: Call[]) {
    this.calls = calls;
    this.groupedCallsByCreated = this.groupCallsByCreated();
  }

  groupCallsByCreated(): Call[][] {
    const groupedCalls: { [key: string]: Call[] } = {};

    this.calls.forEach((call) => {
      const createdKey = new Date(call.created).toISOString().split('T')[0];
      if (!groupedCalls[createdKey]) {
        groupedCalls[createdKey] = [];
      }
      groupedCalls[createdKey].push(call);
    });

    Object.keys(groupedCalls).forEach((key) => {
      groupedCalls[key].sort((a, b) => b.call_id - a.call_id);
    });

    return Object.keys(groupedCalls)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((key) => groupedCalls[key]);
  }

  showListAction(index: number) {
    this.showListItems[index] = !this.showListItems[index];
  }

  hideList(index: number): boolean {
    return this.showListItems[index];
  }

  setRouteSubscriptions() {
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(params && Number(params['call_id'])){
        this.paramID = Number(params['call_id']);
      }
    })
  }
}
